import { defineComponent } from 'vue';
import store from '@/store';
import WatFocusTesterCalendar from './WatFocusTesterCalendar.vue';
import WatFocusConectivityTest from './WatFocusConectivityTest.vue';
import WatFocusTesterInfo from './WatFocusTesterInfo.vue';
import WatFocusWelcomePage from './WatFocusWelcomePage.vue';
import ModalBasic from '~/components/ModalBasic';
export default defineComponent({
    name: 'WatFocusTask',
    components: {
        WatFocusTesterCalendar: WatFocusTesterCalendar,
        WatFocusConectivityTest: WatFocusConectivityTest,
        ModalBasic: ModalBasic,
        WatFocusTesterInfo: WatFocusTesterInfo,
        WatFocusWelcomePage: WatFocusWelcomePage
    },
    props: {
        id: {
            type: String,
            default: null,
        },
        testTitle: {
            type: String,
            default: null
        },
        testCountry: {
            type: String,
            default: 'ES'
        },
        watFocusConfig: {
            type: Object,
            default: function () { }
        }
    },
    computed: {
        pageTitle: function () {
            if (this.showCalendar)
                return this.$t('wat_focus_calendar_title');
            if (this.showConnectivity)
                return this.$t('wat_focus_connectivity_test_title');
            if (this.showTesterInfo)
                return this.$t('wat_focus_tester_title');
        },
        pageInfo: function () {
            if (this.showCalendar)
                return this.$t('wat_focus_calendar_subtitle');
            if (this.showConnectivity)
                return this.$t('wat_focus_connectivity_test_desc');
            if (this.showTesterInfo)
                return this.$t('wat_focus_tester_info');
        }
    },
    data: function () {
        return {
            showCalendar: false,
            showConnectivity: false,
            showModal: false,
            showTesterInfo: false,
            showWatFocusWelcome: false,
            provinces: []
        };
    },
    methods: {
        getProvinces: function () {
            var _this = this;
            store.dispatch("getProvinces", { country: this.testCountry }).then(function (data) {
                _this.provinces = data;
            });
        },
        backToCalendar: function () {
            this.showTesterInfo = false;
            this.showCalendar = true;
        },
        confirmCalendarDates: function (event) {
            if (!this.isPublic()) {
                this.showModal = true;
            }
            else {
                this.showCalendar = false;
                this.showTesterInfo = true;
            }
            this.$emit('save-calendar', event);
        },
        saveTesterInfo: function (event) {
            this.showModal = true;
            this.$emit('save-tester', event);
        },
        saveConectivityTest: function (event) {
            this.$emit('save-connectivity', event);
        },
        welcomePageFinish: function () {
            this.showWatFocusWelcome = false;
            this.showCalendar = true;
        },
        advertAccepted: function () {
            this.showTesterInfo = false;
            this.showCalendar = false;
            this.showModal = false;
            this.showConnectivity = true;
        },
        isPublic: function () {
            return process.env.VUE_APP_ENVIROMENT == "WAT_EXECUTION";
        },
        init: function () {
            if (this.isPublic()) {
                this.showWatFocusWelcome = true;
            }
            else {
                this.showCalendar = true;
            }
        }
    },
    mounted: function () {
        this.getProvinces();
        this.init();
    }
});
